exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contents-mdx-frontmatter-slug-tsx-content-file-path-vercel-path-0-contents-history-of-javascript-index-mdx": () => import("./../../../src/pages/contents/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/contents/history-of-javascript/index.mdx" /* webpackChunkName: "component---src-pages-contents-mdx-frontmatter-slug-tsx-content-file-path-vercel-path-0-contents-history-of-javascript-index-mdx" */),
  "component---src-pages-contents-mdx-frontmatter-slug-tsx-content-file-path-vercel-path-0-contents-how-browser-works-index-mdx": () => import("./../../../src/pages/contents/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/contents/how-browser-works/index.mdx" /* webpackChunkName: "component---src-pages-contents-mdx-frontmatter-slug-tsx-content-file-path-vercel-path-0-contents-how-browser-works-index-mdx" */),
  "component---src-pages-contents-mdx-frontmatter-slug-tsx-content-file-path-vercel-path-0-contents-how-javascript-works-index-mdx": () => import("./../../../src/pages/contents/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/contents/how-javascript-works/index.mdx" /* webpackChunkName: "component---src-pages-contents-mdx-frontmatter-slug-tsx-content-file-path-vercel-path-0-contents-how-javascript-works-index-mdx" */),
  "component---src-pages-contents-mdx-frontmatter-slug-tsx-content-file-path-vercel-path-0-contents-what-changes-in-typescript-5-1-index-mdx": () => import("./../../../src/pages/contents/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/contents/what-changes-in-typescript-5-1/index.mdx" /* webpackChunkName: "component---src-pages-contents-mdx-frontmatter-slug-tsx-content-file-path-vercel-path-0-contents-what-changes-in-typescript-5-1-index-mdx" */),
  "component---src-pages-contents-mdx-frontmatter-slug-tsx-content-file-path-vercel-path-0-contents-why-cors-index-mdx": () => import("./../../../src/pages/contents/{mdx.frontmatter__slug}.tsx?__contentFilePath=/vercel/path0/contents/why-cors/index.mdx" /* webpackChunkName: "component---src-pages-contents-mdx-frontmatter-slug-tsx-content-file-path-vercel-path-0-contents-why-cors-index-mdx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

